

.grid-container {
  display: grid;
  grid-template-columns: repeat(30, 20px);
  grid-template-rows: repeat(30, 20px);
  gap: 2px;
  max-width: 600px;
  height: auto;
}

.horizontal-rectangle {
  grid-column: 3 / span 7;
  grid-row: 5 / span 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: lightblue;
  border: 2px solid red; /* Add a red border to the horizontal rectangle */
  margin: -2px; /* Compensate for the border thickness to avoid overlapping */
}

.square {
  line-height: normal;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.5); /* Add a transparent border around each square */
}




.pxt-header {
  text-align: center;
  color: #f7e85c; /* Dark blue color */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font stack */
  font-weight: bold;
  font-size: 28px; /* Larger font size */
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 2px; /* Add letter spacing */
  margin-bottom: 20px; /* Add bottom margin for spacing */
  /* Add more styles as needed */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Add text shadow */
  background-image: linear-gradient(to bottom, #3498db, #2980b9); /* Gradient background */
  padding: 20px; /* Add padding for better spacing */
  border-radius: 15px; /* Add border radius for rounded corners */
  transition: all 0.3s ease; /* Add smooth transition effect */
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
  display: flex; /* Use flexbox for better alignment */
  align-items: center; /* Center align items vertically */
  justify-content: center; /* Center align items horizontally */
}
.pxt-image {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  border-radius: 10px; /* Add rounded corners */
  background-color: beige;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  transition: all 0.3s ease; /* Add smooth transition effect */
}

.pxt-h5 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font stack */
  font-size: 20px; /* Adjust font size */
  color: #333; /* Text color */
  margin-top: 20px; /* Add top margin for spacing */
  margin-bottom: 10px; /* Add bottom margin for spacing */
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 1px; /* Add letter spacing */
  color: darkblue;
  /* border-bottom: 1px solid #3498db;  */

  /* Add more styles as needed */
}

.pxt-question {
  font-size: 18px; /* Adjust font size */
  color: #1e155a; /* Text color */
  margin-top: 10px; /* Add top margin for spacing */
  margin-top: 10px; /* Add top margin for spacing */
  margin-bottom: 10px; /* Add bottom margin for spacing */
  padding: 10px 10px 10px 20px; /* Add padding for spacing within the box */
  background-color: #dcdce3; /* Background color for the question */
  border-radius: 10px; /* Add rounded corners for a softer look */
}


.pxt-h3 {
  font-family: 'Roboto', Arial, sans-serif; /* Modern font stack */
  font-size: 24px; /* Adjust font size */
  color: #2c3e50; /* Text color */
  margin-top: 20px; /* Add top margin for spacing */
  margin-bottom: 10px; /* Add bottom margin for spacing */
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 1px; /* Add letter spacing */
  border-bottom: 2px solid #3498db; /* Add a bottom border */
  padding-bottom: 5px; /* Add padding to the bottom */
}

.pxt-button {
  padding: 10px 20px;
  background-color: #0aaee4;
  color: #83ff07;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin: 5px; 
}

.pxt-button:hover {
  background-color: #0056b3;
}

.pxt-info-button {
  padding: 1px 5px;
  background-color: #0aaee4;
  color: #83ff07;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pxt-info-button:hover {
  background-color: #0056b3;
}

.pxt-info-tab-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.pxt-info-tab-title {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

.pxt-info-tab-content {
  color: #666;
  font-size: 16px;
}

#tooltip-anchor {
  text-decoration: none;
  border-bottom: 1px dashed black; /* Apply dashed underline */
}

/* 
.tooltip-trigger {
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

.tooltip-trigger::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  border-bottom: 1px dashed #000;
  pointer-events: none; 
} */

.example-container .example-orange {
  color: #222;
  background-color: rgb(255, 153, 0);
}



@keyframes flicker {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.5;
  }
  100% {
      opacity: 1;
  }
}

.flickering-text {
  animation: flicker 1s infinite; /* Adjust animation duration and iteration as needed */
}


/* :root {
  --green: rgb(39, 189, 39);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Playfair Display", serif;
}

h2 {
  margin-bottom: 1rem;
}

button {
  padding: 0.25rem 1rem;
  font-family: inherit;
  border: none;
  cursor: pointer;
}

button.primary {
  background-color: var(--green);
  color: white;
}

.app {
  margin: 0 auto;
  max-width: 1200px;
}

header h1 {
  font-size: 2.5em;
  font-weight: 900;
  text-align: center;
  padding: 0.5rem 0;
}

.logo {
  font-size: 1.25em;
  padding-left: 0.5rem;
  -webkit-text-stroke: 2px #000000;
}

main {
  padding: 0 1rem;
}

.new-bird-form {
  padding: 2rem 1rem;
  margin: 2rem 0;
  background-color: rgba(181, 250, 181, 0.5);
}

.new-bird-form form {
  display: flex;
  justify-content: center;
}

.new-bird-form input {
  margin-right: 2rem;
  flex: 1;
}

.new-bird-form button {
  width: 150px;
}

.searchbar {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  display: block;
}

input {
  padding: 0.25rem;
  border: none;
  font-family: inherit;
  font-size: 1.2em;
  border-bottom: 2px solid gray;
  transition: all 0.2s;
  width: 100%;
}

input:focus {
  outline: none;
  border-bottom: 2px solid var(--green);
}

.cards {
  list-style: none;
}

.card, .details {
  margin: 1rem;
  border: 1px solid black;
  padding: 10px;
}

.card img {
  width: 100%;
}

.card button {
  margin-top: 0.5rem;
}

.details {
  max-width: 50%;
  text-align: center;
  margin-left: auto; 
  margin-right: auto; 
  height: auto;
}

.details img {
  max-width: 85%;
}

@media (min-width: 768px) {
  .cards {
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    width: calc(33% - 2rem);
  }
}

@media (min-width: 1024px) {
  .card {
    width: calc(25% - 2rem);
  }
}

@media (min-width: 1200px) {
  .card {
    width: calc(20% - 2rem);
  }
} */
